import React from "react"
import styled from "styled-components"

import SEO from "../components/seo"
import { Layout } from "../components/Layout/Layout"
import { Slider } from "../components/Slider/Slider"
import { media } from "../utils/media"

const StyledWrapper = styled.article`
  h1 {
    margin-bottom: 2rem;
  }

  .Row {
    display: flex;
    color: #333;

    flex-direction: column;

    div {
      flex-basis: 50%;
      margin-bottom: 1.5rem;

      ${media.sm`
        margin-bottom: 0;
      `}
    }

    ${media.sm`
      flex-direction: row;
    `}

    h2 {
      font-size: 1.25rem;
    }
  }

  .Contact__List {
    font-size: 1rem;
    list-style-type: none;
    margin: 0;
    padding: 0;
    margin-bottom: 1rem;
  }

  .Contact__List .Contact__Item {
    display: block;
    padding: 0.5rem 0;
    margin-bottom: 0.5rem;
    border-bottom: 1px solid #ccc;

    ${media.sm`
      width: 90%;
    `}

    a {
      background-image: none;
      color: #333;

      &:hover {
        color: #3f3f3f;
        text-decoration: underline;
      }
    }
  }
`

export default () => {
  return (
    <Layout>
      <SEO title="Kontakt" />
      <Slider />
      <StyledWrapper class="Wrapper">
        <h1 class="Section__Title">Kontakt</h1>
        <div class="Row">
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Sekretariat</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146635560">14 664 55 60</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146863370">14 686 33 70</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146863370">fax: 14 663 26 09</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:sekretariat@edmund-les.com.pl">
                  sekretariat@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Księgowość</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146635563">14 663 55 63</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146635576">14 663 55 76</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:ksiegowosc@edmund-les.com.pl">
                  ksiegowosc@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="Row">
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Biuro sprzedaży mieszkań w Krakowie</h2>
            <p class="Contact__Subtitle">ul. Warszawska 3/1, Kraków</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:126358545">12 635 85 45</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:mieszkania@edmund-les.com.pl">
                  mieszkania@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Biuro sprzedaży mieszkań w Brzesku</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146863370">14 686 33 70</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="Row">
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Dział handlowy Brzesko</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <strong>Michał Leś - kierownik sprzedaży</strong>
              <li class="Contact__Item">
                <a href="tel:+48697698644">+48 697 698 644</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:michal.les@edmund-les.com.pl">
                  michal.les@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>
                Jarosław Komęza - doradca techniczno-handlowy, logistyk
              </strong>
              <li class="Contact__Item">
                <a href="tel:+48693349480">+48 693 349 480</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:jarek.komeza@edmund-les.com.pl">
                  jarek.komeza@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>
                Piotr Maruniak - doradca techniczno-handlowy, logistyk
              </strong>
              <li class="Contact__Item">
                <a href="tel:+48791534393">+48 791 534 393</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:piotr.maruniak@edmund-les.com.pl">
                  piotr.maruniak@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Wojciech Kukla - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693340502">+48 693-340-502</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:wojciech.kukla@edmund-les.com.pl">
                  wojciech.kukla@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Paweł Gicala - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693431596">+48 693 431 596</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:pawel.gicala@edmund-les.com.pl">
                  pawel.gicala@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Andrzej Kozak - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693338059">+48 693 338 059</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:andrzej.kozak@edmund-les.com.pl">
                  andrzej.kozak@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Tomasz Stobierski - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693330302">+48 693 330 302</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:t.stobierski@edmund-les.com.pl">
                  t.stobierski@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Dział handlowy Kraków</h2>
            <p class="Contact__Subtitle">ul. Gustawa Morcinka 5, Kraków</p>
            <ul class="Contact__List">
              <strong>Dariusz Michałek - kierownik sprzedaży</strong>
              <li class="Contact__Item">
                <a href="tel:+48693349464">+48 693 349 464</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:centralna@edmund-les.com.pl">
                  centralna@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Waldemar Ryba - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48782822318">+48 782 822 318</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:waldemar.ryba@edmund-les.com.pl">
                  waldemar.ryba@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Szymon Konarski - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693349478">+48 693-349-478</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:szymon.konarski@edmund-les.com.pl">
                  szymon.konarski@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Łukasz Urych - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693331285">+48 693-331-285</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:lukasz.urych@edmund-les.com.pl">
                  lukasz.urych@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="Row">
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Skład budowlany o/ Nowy Sącz</h2>
            <p class="Contact__Subtitle">ul. Krajewskiego 27, Nowy Sącz</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:+48693330085">+48 693 330 085</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:nowysacz@edmund-les.com.pl">
                  nowysacz@edmund-les.com.pl
                </a>
              </li>
            </ul>
            <ul class="Contact__List">
              <strong>Marcin Niedźwiedź - doradca techniczno-handlowy</strong>
              <li class="Contact__Item">
                <a href="tel:+48693330085">+48 693 330 085</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:marcin.niedzwiedz@edmund-les.com.pl">
                  marcin.niedzwiedz@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Magazyn stali</h2>
            <p class="Contact__Subtitle">ul. Solskiego 6, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146867444">14 68 67 444</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:stal@edmund-les.com.pl">
                  stal@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="Row">
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Dom i ogród</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146632330">14 663 23 30</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146635567">14 663 55 67</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:ogrod@edmund-les.com.pl">
                  ogrod@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Dział handlowy - sprzedaż hurtowa</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146635566">14 663 55 66</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146635568">14 663 55 68</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146635565">fax: 14 663 55 65</a>
              </li>
              <li class="Contact__Item">
                <strong>Agata Batko-Kucharz</strong>{" "}
                <a href="tel:+48693349461">+48 693 349 461</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:hurt@edmund-les.com.pl">
                  hurt@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="Row">
          <div class="Col-Medium-6 Contact">
            <h2 class="Contact__Title">Dział zaopatrzenia</h2>
            <p class="Contact__Subtitle">ul. Solskiego 14a, 32-800 Brzesko</p>
            <ul class="Contact__List">
              <li class="Contact__Item">
                <a href="tel:146635572">14 663 55 72</a>
              </li>
              <li class="Contact__Item">
                <a href="tel:146632331">fax: 14 663 23 31</a>
              </li>
              <li class="Contact__Item">
                <strong>Adam Gutowicz</strong>{" "}
                <a href="tel:+48693349481">+48 693 349 481</a>
              </li>
              <li class="Contact__Item">
                <strong>Paweł Leś</strong>{" "}
                <a href="tel:+48605820147">+48 605 820 147</a>
              </li>
              <li class="Contact__Item">
                <strong>Magdalena Sowińska</strong>{" "}
                <a href="tel:+48693338058">+48 693 338 058</a>
              </li>
              <li class="Contact__Item">
                <a href="mailto:hurt@edmund-les.com.pl">
                  zaopatrzenie@edmund-les.com.pl
                </a>
              </li>
            </ul>
          </div>
        </div>
      </StyledWrapper>
    </Layout>
  )
}
